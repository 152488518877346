import { tw } from '@/utils/tw';
import CheckboxTheme from 'base/components/Checkbox/theme';

const { Caption } = CheckboxTheme;

const Checkbox = tw.theme({
  extend: CheckboxTheme,
  slots: {
    label: ['ml-2.5', 'text-headline-xs'],
    input: [
      'active:bg-gray-100',
      'active:border-gray-600',
      'active:ring-0',
      'appearance-none',
      'before:block',
      'before:checked:border-b-3',
      'before:checked:border-r-3',
      'before:checked:border-white',
      'before:checked:h-4',
      'before:checked:ml-1.5',
      'before:checked:origin-center',
      'before:checked:rotate-45',
      'before:checked:w-2',
      'border-2',
      'border-gray-300',
      'box-border',
      'checked:active:border-success-700',
      'checked:bg-gradient-to-b',
      'checked:border-transparent',
      'checked:from-success-400',
      'checked:to-success-500',
      'clip-path-circle-[65%_at_50%_50%]',
      'focus:border-success-700',
      'focus:clip-path-circle-[70%_at_50%_50%]',
      'focus:ring-8',
      'focus:ring-success-200',
      'h-6',
      'hover:ring-8',
      'hover:ring-success-200',
      'outline-0',
      'rounded',
      'w-6',
    ],
  },
  variants: {
    variant: {
      alternative: {
        input: [
          'active:bg-success-200',
          'active:border-transparent',
          'active:ring-0',
          'after:bg-black',
          'after:block',
          'after:bottom-7',
          'after:checked:hidden',
          'after:focus:bottom-6.5',
          'after:focus:right-0.5',
          'after:h-5',
          'after:mx-4.25',
          'after:relative',
          'after:w-0.5',
          'appearance-none',
          'before:bg-black',
          'before:block',
          'before:checked:bg-transparent',
          'before:checked:border-b-2',
          'before:checked:border-r-2',
          'before:checked:border-white',
          'before:checked:h-4.5',
          'before:checked:mx-3',
          'before:checked:my-1.75',
          'before:checked:origin-center',
          'before:checked:rotate-45',
          'before:checked:w-2.5',
          'before:focus:checked:mx-2.5',
          'before:focus:checked:my-1.25',
          'before:focus:mx-1.5',
          'before:focus:my-3.75',
          'before:h-0.5',
          'before:mx-2',
          'before:my-4.25',
          'before:w-5',
          'checked:active:bg-cherry-blossom-400',
          'checked:active:border-transparent',
          'checked:active:ring-0',
          'checked:active:shadow-none',
          'checked:bg-cherry-blossom-400',
          'checked:focus:border-2',
          'checked:focus:border-cherry-blossom-500',
          'checked:focus:ring-4',
          'checked:focus:ring-success-200',
          'checked:shadow-none',
          'focus:border-2',
          'focus:border-success-700',
          'focus:ring-4',
          'focus:ring-success-200',
          'h-9',
          'hover:ring-4',
          'hover:ring-success-200',
          'outline-0',
          'rounded-full',
          'shadow-gray4',
          'w-9',
        ],
      },
    },
  },
});

export default Object.assign(Checkbox, { Caption });
